export default {
	// This is the list of languages your application supports
	supportedLngs: ['de', 'en'],
	// This is the language you want to use in case
	// if the user language is not in the supportedLngs
	fallbackLng: 'de', //? main language?
	//   fallbackLng: ['de', 'en'],
	// The default namespace of i18next is "translation", but you can customize it here
	// defaultNS: 'common',
	// Disabling suspense is recommended
	react: { useSuspense: false },
	cleanCode: true,
	//   lng: 'de',
	preload: ['de', 'en'],
	ns: [
		'common',
		'index',
		'topbar',
		'footer',
		'notFound',
		'contact',
		'hireUs',
		'about',
		'network',
		'references',
		'websites',
		'applications',
		'workflows',
		'legal',
		'privacy',
		'policies',
		'project_stk-website',
		'project_md-app',
		'project_maintain-form',
		'project_fitseveneleven-api',
		'project_stk-portal',
		'project_yobo-website',
	],
};
